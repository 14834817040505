import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = { class: "row w-100 h-100" }
const _hoisted_3 = { class: "col-sm-6 h-100" }
const _hoisted_4 = { class: "notesContainer" }
const _hoisted_5 = { class: "scrollSection" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "d-flex align-items-center justify-content-between mb-1" }
const _hoisted_8 = {
  key: 0,
  class: "creator"
}
const _hoisted_9 = {
  key: 1,
  class: "creator"
}
const _hoisted_10 = { class: "date" }
const _hoisted_11 = { class: "text" }
const _hoisted_12 = { class: "col-sm-6 h-100" }
const _hoisted_13 = { class: "notesContainer" }
const _hoisted_14 = { class: "scrollSection" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "d-flex align-items-center justify-content-between mb-1" }
const _hoisted_17 = {
  key: 0,
  class: "creator"
}
const _hoisted_18 = {
  key: 1,
  class: "creator"
}
const _hoisted_19 = { class: "date" }
const _hoisted_20 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-primary fixedRightButton",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editNote(null)))
    }, [
      _createVNode(_component_localized_text, {
        localizedKey: "Nuova nota",
        text: "Nuova nota"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_localized_text, {
            class: "d-block fs_20 fw_700 mb-5",
            localizedKey: "Pubbliche",
            text: "Pubbliche"
          }),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.publicNotes.length == 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessuna nota presente",
                    text: "Nessuna nota presente"
                  })
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.publicNotes, (note, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "note",
                key: index
              }, [
                _createElementVNode("div", _hoisted_7, [
                  (note.createBy && note.createBy.text)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(note.createBy.text), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_9, "Admin")),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$filters.date(note.createOn, "YYYY MMMM DD HH:mm")), 1)
                ]),
                _createElementVNode("span", _hoisted_11, _toDisplayString(note.notes), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_localized_text, {
            class: "d-block fs_20 fw_700 mb-5",
            localizedKey: "Private",
            text: "Private"
          }),
          _createElementVNode("div", _hoisted_14, [
            (_ctx.privateNotes.length == 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessuna nota presente",
                    text: "Nessuna nota presente"
                  })
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.privateNotes, (note, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "note",
                key: index
              }, [
                _createElementVNode("div", _hoisted_16, [
                  (note.createBy && note.createBy.text)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(note.createBy.text), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_18, "Admin")),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$filters.date(note.createOn, "YYYY MMMM DD HH:mm")), 1)
                ]),
                _createElementVNode("span", _hoisted_20, _toDisplayString(note.notes), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}