
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { AdminClient } from '@/services/Services';
import { showNotification } from '@/utils';
import DocumentManagement from '@/components/documentsManagement.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        DocumentManagement
    }
})
export default class ContractInfo extends Vue {

    @Prop({
        default: ""
    }) employeeIdentifier: string;

    contractInfo: OM.JobContractVm = new OM.JobContractVm();
    daysOfWeek: VM.DayOfWeek[] = [];
    rnd: number = 0;

    isIndeterminato: boolean = true;

    created() {
        this.daysOfWeek = Object.keys(OM.DayOfWeek)
            .filter( x => !isNaN(<any>x))
            .map( key => { 
                return {
                    text: OM.DayOfWeek[key],
                    value: parseInt(key)
                }
            })

        this.init();
    }

    init() {
        AdminClient.getEmployeeInfo(this.employeeIdentifier)
        .then(x => {
            this.contractInfo = x.jobContract;
            this.isIndeterminato = this.contractInfo.contractExpirationDate ? false : true;

            this.rnd = Math.random();
        })
    }

    clearDate() {
        this.isIndeterminato = true;
        this.contractInfo.contractExpirationDate = null;
    }

    toggleDay(selectedDay: number) {
        var findIndex = this.contractInfo.workingDays.indexOf(selectedDay);

        if(findIndex == -1)
            this.contractInfo.workingDays.push(selectedDay);
        else
            this.contractInfo.workingDays.splice(findIndex, 1);
    }
    isInWorkingDays(selectedDay: number) {
        return this.contractInfo.workingDays.indexOf(selectedDay) > -1;
    }

    get saveDisabled() {
        return !this.contractInfo.contractType || !this.contractInfo.availabilityForWeek || !this.contractInfo.availabilityForMonth || this.contractInfo.workingDays.length == 0 ||
            (!this.isIndeterminato && !this.contractInfo.contractExpirationDate);
    }

    addFile(newJobContractFile: File) {
        if(!newJobContractFile)
            return;

        AdminClient.updateJobContractFile(newJobContractFile, this.employeeIdentifier, "")
        .then(x => {
            this.save();
        })
    }
    editFile(editJobContractFile: VM.EditFile) {
        if(!editJobContractFile.file)
            return;

        AdminClient.updateJobContractFile(editJobContractFile.file, this.employeeIdentifier, editJobContractFile.fileIdentifier)
        .then(x => {
            this.save();
        })
    }

    save() {
        AdminClient.updateJobContract(this.contractInfo)
        .then(x => {
            showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));
            this.init();
        })
    }

}
