
import { Options, Vue } from 'vue-class-component';
import BaseUserInfo from './components/baseUserInfo.vue';
import PersonalInfo from './components/personalInfo.vue';
import ContractInfo from './components/contractInfo.vue';
import SecurityInfo from './components/securityInfo.vue';
import EquipmentInfo from './components/equipmentInfo.vue';
import NoteInfo from './components/noteInfo.vue';

@Options({
    components: {
        BaseUserInfo,
        PersonalInfo,
        ContractInfo,
        SecurityInfo,
        EquipmentInfo,
        NoteInfo
    }
})
export default class EditEmployee extends Vue {

    employeeIdentifier: string = "";
    activeSection: number = 1;

    created() {
        this.employeeIdentifier = this.$route.params.employeeIdentifier ? this.$route.params.employeeIdentifier.toString() : "";
    }

    updateIdentifierValue(idValue: string) {
        this.employeeIdentifier = idValue;
    }

}
