
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminClient } from '@/services/Services';
import { showNotification } from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class PersonalInfo extends Vue {

    @Prop({
        default: ""
    }) employeeIdentifier: string;

    employeeData: OM.EmployeeDataVM = new OM.EmployeeDataVM();
    localEmployeeInfo : { [key: string]: string; } = {};

    created() {
        this.init();
    }

    init() {
        if(!this.employeeIdentifier)
            return;

        AdminClient.getEmployeeInfo(this.employeeIdentifier)
        .then(x => {
            this.employeeData = x;

            var propertyList = Object.keys(this.employeeData.employeeInfo);
            propertyList.forEach(element => {
                this.localEmployeeInfo[element] = this.employeeData.employeeInfo[element];
            });
        })
    }

    get isEmpty() {
        var keys = Object.keys(this.localEmployeeInfo);
        return keys.length == 0;
    }

    save() {
        var vm = new OM.ItemUpdatePropertiesVM();
        vm.itemIdentifier = this.employeeIdentifier;
        vm.propertiesToUpdate = this.localEmployeeInfo;

        AdminClient.updateEmployeePersonalProperties(vm)
        .then(x => {
            showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));

            this.init();
        })
    }

}
