import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row w-100" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "input_title" }
const _hoisted_7 = { class: "d-flex flex-wrap mt-3" }
const _hoisted_8 = {
  key: 0,
  class: "col-sm-6"
}
const _hoisted_9 = { class: "row w-100" }
const _hoisted_10 = { class: "col-sm-6" }
const _hoisted_11 = { class: "col-sm-6" }
const _hoisted_12 = { class: "row w-100" }
const _hoisted_13 = { class: "col-sm-12" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "input_title" }
const _hoisted_16 = { class: "d-flex flex-wrap mt-3" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_document_management = _resolveComponent("document-management")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_form_field, {
          type: "text",
          labelKey: "Tipo Contratto",
          labelValue: "Tipo Contratto",
          placeholderKey: "Tipo Contratto",
          placeholderValue: "Tipo Contratto",
          required: true,
          modelValue: _ctx.contractInfo.contractType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contractInfo.contractType) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.isIndeterminato ? 'col-sm-12' : 'col-sm-6')
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Durata",
                  text: "Durata"
                }),
                _createTextVNode("* ")
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["labelAsCheck", {'active' : _ctx.isIndeterminato}]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearDate()))
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Indeterminato",
                    text: "Indeterminato"
                  })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["labelAsCheck", {'active' : !_ctx.isIndeterminato}]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isIndeterminato = false))
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Determinato",
                    text: "Determinato"
                  })
                ], 2)
              ])
            ])
          ], 2),
          (!_ctx.isIndeterminato)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_form_field, {
                  type: "date",
                  labelKey: "Scadenza",
                  labelValue: "Scadenza",
                  placeholderKey: "Scadenza",
                  placeholderValue: "Scadenza",
                  required: true,
                  modelValue: _ctx.contractInfo.contractExpirationDate,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contractInfo.contractExpirationDate) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_form_field, {
          type: "number",
          labelKey: "Disponibilità settimanale",
          labelValue: "Disponibilità settimanale",
          placeholderKey: "Disponibilità settimanale",
          placeholderValue: "Disponibilità settimanale",
          required: true,
          modelValue: _ctx.contractInfo.availabilityForWeek,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contractInfo.availabilityForWeek) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_form_field, {
          type: "number",
          labelKey: "Disponibilità mensile",
          labelValue: "Disponibilità mensile",
          placeholderKey: "Disponibilità mensile",
          placeholderValue: "Disponibilità mensile",
          required: true,
          modelValue: _ctx.contractInfo.availabilityForMonth,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contractInfo.availabilityForMonth) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", _hoisted_15, [
            _createVNode(_component_localized_text, {
              localizedKey: "Disponibilità",
              text: "Disponibilità"
            }),
            _createTextVNode("* ")
          ]),
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOfWeek, (day, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["labelAsCheck", {'active' : _ctx.isInWorkingDays(day.value)}]),
                key: index,
                onClick: ($event: any) => (_ctx.toggleDay(day.value))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: day.text,
                  text: day.text
                }, null, 8, ["localizedKey", "text"])
              ], 10, _hoisted_17))
            }), 128))
          ])
        ])
      ])
    ]),
    (_openBlock(), _createBlock(_component_document_management, {
      sectionTitle: "Documenti (PDF, PNG, JPG, JPEG)",
      onlyImage: false,
      urlAttachments: _ctx.contractInfo.urlAttachments,
      onNewFileAdded: _ctx.addFile,
      onFileUpdated: _ctx.editFile,
      key: _ctx.rnd
    }, null, 8, ["urlAttachments", "onNewFileAdded", "onFileUpdated"])),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save())),
        disabled: _ctx.saveDisabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Salva",
          text: "Salva"
        })
      ], 8, _hoisted_19)
    ])
  ]))
}