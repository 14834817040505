
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminClient, HeadquartersClient } from '@/services/Services';
import { showNotification } from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class BaseUserInfo extends Vue {

    @Prop({
        default: ""
    }) employeeIdentifier: string;
    localEmployeeIdentifier: string = "";

    employeeBasicInfo: OM.UserEditVM = new OM.UserEditVM();
    employeeUserInfo: OM.UserInfoVM = new OM.UserInfoVM();
    password: string = "";
    
    headquartersList: OM.TextIdentifier[] = [];
    selectedHeadquarterId: string = "";

    created() {
        this.localEmployeeIdentifier = this.employeeIdentifier;

        HeadquartersClient.getAllAsSelect()
        .then(x => {
            this.headquartersList = x;
            this.init();
        })
    }

    init() {
        if(!this.localEmployeeIdentifier)
            return;

        AdminClient.getUserInfo(this.localEmployeeIdentifier)
        .then(x => {
            this.employeeUserInfo = x;
            this.employeeBasicInfo = {...this.employeeUserInfo};
            this.selectedHeadquarterId = this.employeeUserInfo.heaquarters.identifier;
        })
    }

    get saveDisabled() {
        return !this.employeeBasicInfo.loginData.username || !this.password || !this.employeeBasicInfo.personalData.name || !this.employeeBasicInfo.personalData.surname || !this.selectedHeadquarterId;
    }

    save() {
        if(!this.localEmployeeIdentifier)
            this.employeeBasicInfo.identifier = null;

        if(this.password)
            this.employeeBasicInfo.loginData.password = this.password;

        AdminClient.editBaseUser(this.employeeBasicInfo)
        .then(x => {
            this.localEmployeeIdentifier = x;

            AdminClient.updateEmployeeHeadquarters(this.localEmployeeIdentifier, this.selectedHeadquarterId)
            .then(x => {
                showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));

                this.$emit('updateIdentifier', this.localEmployeeIdentifier);
                this.init();
            })
        })
    }

}
