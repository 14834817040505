
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminClient, BaseUserClient } from '@/services/Services';
import EditNoteModal from '@/components/modals/editNoteModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class NoteInfo extends Vue {

    @Prop({
        default: ""
    }) employeeIdentifier: string;

    privateNotes: OM.NoteVm[] = [];
    publicNotes: OM.NoteVm[] = [];

    created() {
        this.init();
    }

    init() {
        if(!this.employeeIdentifier)
            return;

        Promise.all([
            AdminClient.getAllPrivateNotes(this.employeeIdentifier),
            BaseUserClient.getAllPublicNotes(this.employeeIdentifier)
        ])
        .then(xs => {
            this.privateNotes = xs[0];
            this.publicNotes = xs[1];
        })
    }

    editNote(note: OM.NoteVm) {
        if(note == null)
            note = new OM.NoteVm();

        this.$opModal.show(EditNoteModal, {
            note: note,
            callback: (isPrivate: boolean, text: string) => {
                var proms = [];

                if(!note.id) {
                    if(isPrivate)
                        proms.push(AdminClient.addPrivateNotes(this.employeeIdentifier, text));
                    else
                        proms.push(BaseUserClient.addPublicNotes(this.employeeIdentifier, text));
                }
                else
                    proms.push(BaseUserClient.updateNote(this.employeeIdentifier, note.id, text));

                Promise.all(proms)
                .then(xs => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

}
