import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row w-100" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row w-100" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "col-sm-6" }
const _hoisted_7 = { class: "row w-100" }
const _hoisted_8 = { class: "col-sm-6" }
const _hoisted_9 = { class: "col-sm-6" }
const _hoisted_10 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_form_field, {
          type: "text",
          labelKey: "ID ProShift",
          labelValue: "ID ProShift",
          placeholderKey: "ID ProShift",
          placeholderValue: "ID ProShift",
          required: true,
          modelValue: _ctx.employeeBasicInfo.idProShift,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.employeeBasicInfo.idProShift) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_form_field, {
          type: "select",
          labelKey: "Magazzino",
          labelValue: "Magazzino",
          placeholderKey: "Magazzino",
          placeholderValue: "Magazzino",
          required: true,
          modelValue: _ctx.selectedHeadquarterId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedHeadquarterId) = $event)),
          list: _ctx.headquartersList
        }, null, 8, ["modelValue", "list"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_form_field, {
          type: "email",
          labelKey: "Email",
          labelValue: "Email",
          placeholderKey: "Email",
          placeholderValue: "Email",
          required: true,
          modelValue: _ctx.employeeBasicInfo.loginData.username,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.employeeBasicInfo.loginData.username) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_form_field, {
          type: "password",
          labelKey: "Password",
          labelValue: "Password",
          placeholderKey: "Password",
          placeholderValue: "Password",
          required: true,
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_form_field, {
          type: "text",
          labelKey: "Nome",
          labelValue: "Nome",
          placeholderKey: "Nome",
          placeholderValue: "Nome",
          required: true,
          modelValue: _ctx.employeeBasicInfo.personalData.name,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.employeeBasicInfo.personalData.name) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_form_field, {
          type: "text",
          labelKey: "Cognome",
          labelValue: "Cognome",
          placeholderKey: "Cognome",
          placeholderValue: "Cognome",
          required: true,
          modelValue: _ctx.employeeBasicInfo.personalData.surname,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.employeeBasicInfo.personalData.surname) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save())),
        disabled: _ctx.saveDisabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Salva",
          text: "Salva"
        })
      ], 8, _hoisted_11)
    ])
  ]))
}