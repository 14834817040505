
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminClient } from '@/services/Services';
import EditSecurityCertificationModal from '../modals/editSecurityCertificationModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class SecurityInfo extends Vue {

    @Prop({
        default: ""
    }) employeeIdentifier: string;

    certificationList: OM.ListSecurityDataVm[] = [];

    created() {
        this.init();
    }

    init() {
        AdminClient.getEmployeeInfo(this.employeeIdentifier)
        .then(x => {
            this.certificationList = x.securityData;
        })
    }

    editCertification(certification: OM.ListSecurityDataVm) {
        this.$opModal.show(EditSecurityCertificationModal, {
            employeeIdentifier: this.employeeIdentifier,
            certification: certification,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
