
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminClient } from '@/services/Services';
import DocumentManagement from '@/components/documentsManagement.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        DocumentManagement
    }
})
export default class EditSecurityCertificationModal extends Vue {

    @Prop() employeeIdentifier: string;
    @Prop() certification: OM.ListSecurityDataVm;

    localCertification: OM.SecurityDataVm = new OM.SecurityDataVm();
    localDocument: OM.UrlAttachment = new OM.UrlAttachment();

    @Prop() callback: any;

    rnd: number = 0;

    mounted() {
        this.localCertification = {...this.certification};
        this.localCertification.employeeId = this.employeeIdentifier;
        this.localDocument = {...this.certification.document};

        if(!this.localDocument)
            this.localDocument = new OM.UrlAttachment();
    }

    updateDocumentManagement() {
        this.rnd = Math.random();
    }

    addFile(newDocumentFile: File) {
        if(!newDocumentFile)
            return;

        AdminClient.updateSecurityDataFile(newDocumentFile, this.employeeIdentifier, this.localCertification.name, null)
        .then(x => {
            this.localDocument = x;
            this.updateDocumentManagement();
        })
    }
    editFile(editDocumentFile: VM.EditFile) {
        if(!editDocumentFile.file)
            return;

        AdminClient.updateSecurityDataFile(editDocumentFile.file, this.employeeIdentifier, this.localCertification.name, editDocumentFile.fileIdentifier)
        .then(x => {
            this.localDocument = x;
            this.updateDocumentManagement();
        })
    }

    get saveDisabled() {
        return !this.localCertification.expirationDate;
    }
    save() {
        AdminClient.updateSecurityDataValue(this.localCertification)
        .then(x => {
            this.callback();
        })
    }

}
